<template>
  <v-container id="reset-password">
    <v-row>
      <v-col cols="5">
        <v-img
          contain
          src="@/assets/WeCook-Logo_homepage.png"
        ></v-img>
      </v-col>
      <v-col cols="4"></v-col>
      <v-col cols="3" class="text-right d-flex justify-center">
        <v-img
          contain
          src="@/assets/wecook_stickers17.png"
        ></v-img>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-center">
        <div class="title-5">Reset your password</div>
      </v-col>
    </v-row>

    <v-row v-if="isResetPasswordSuccessful">
      <v-col cols="12" class="text-center">
        <div class="title-6">Your password has been reset successfully.</div>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn
          depressed
          class="explore-btn "
          @click="goToMainFeed"
        >
          Explore Food
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12" sm="8" md="6" offset-sm="2" offset-md="3">
        <v-form
          id="reset-password-form"
          ref="reset_password_form"
          v-model="validForm"
          lazy-validation
          @submit.prevent="resetPassword"
        >
          <v-text-field
            v-model="email"
            autocomplete="email"
            :rules="emailRules"
            label="Email"
            background-color="#F2F2F2"
            class="my-text-field"
            color="#001254"
            solo
            flat
            rounded
            height="53"
            required
          ></v-text-field>

          <v-text-field
            v-model="password"
            autocomplete="new-password"
            :rules="passwordRules"
            label="Password"
            type="password"
            background-color="#F2F2F2"
            class="my-text-field"
            color="#001254"
            solo
            flat
            rounded
            height="53"
            required
          ></v-text-field>

          <v-text-field
            v-model="confirmPassword"
            autocomplete="new-password"
            :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
            label="Confirm Password"
            type="password"
            background-color="#F2F2F2"
            class="my-text-field"
            color="#001254"
            solo
            flat
            rounded
            height="53"
            required
          ></v-text-field>

          <v-row>
            <v-col cols="12">
              <v-btn
                block
                depressed
                class="title-12 signin-btn"
                type="submit"
              >
                Reset password
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>

    <v-overlay :value="showLoader" z-index=12>
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

  </v-container>
</template>

<script>
  import axios from 'axios';
  import SignInModal from '@/components/Auth/SignInModal.vue';

  export default {
    name: 'ResetPassword',
    components: {
      SignInModal
    },
    computed: {
      passwordConfirmationRule() {
        return () => this.password === this.confirmPassword || "Password must match";
      }
    },
    data() {
      return {
        showLoader: false,
        validForm: true,
        token: null,
        email: '',
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        password: '',
        passwordRules: [
          value => !!value || 'Password is required.'
        ],
        confirmPassword: '',
        confirmPasswordRules: [
          value => !!value || 'You need to confirm the password.'
        ],
        isResetPasswordSuccessful: false
      };
    },
    mounted() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      this.email = urlParams.get('email');
      this.token = urlParams.get('token');
    },
    methods: {
      resetPassword() {
        let isValidForm = this.$refs.reset_password_form.validate();
        if (isValidForm) {
          this.showLoader = true;
          let url = `${process.env.VUE_APP_BASE_URL}/accounts/We%20Cook/customers/password`;
          let data = {
            email: this.email,
            password: this.password,
            passwordConfirmation: this.confirmPassword,
            token: this.token
          };
          axios.post(url, data).then(res => {
            this.showLoader = false;
            this.isResetPasswordSuccessful = true;
          }).catch(er => {
            console.log(er);
          });
        }
      },
      goToMainFeed() {
        this.$router.push({name: 'menu'});
      },
    }
  };
</script>

<style type="text/css">
  #reset-password .my-text-field {
    margin-bottom: 0;
  }


  #reset-password .explore-btn {
    height: 52px;
    padding: 17px 67px;
    background: #001254;
    border-radius: 44px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    text-transform: capitalize;
  }
</style>